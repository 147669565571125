import { Flex, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import ColorModeSwitch from './ColorModeSwitch';
import TopBarStats from './TopBarStats';

const TopBar = () => {
  const bgColor = useColorModeValue('linear-gradient(154deg, #FAFAFA, #1f4a7d)', 'linear-gradient(154deg, #0c1d31, #1f4a7d)');

  return (
    <Flex
      py={ 2 }
      px={ 6 }
      bgColor={ bgColor }
      justifyContent="space-between"
    >
      <TopBarStats/>
      <ColorModeSwitch/>
    </Flex>
  );
};

export default React.memo(TopBar);

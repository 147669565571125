import type { IconName } from 'ui/shared/IconSvg';

export const COLOR_THEMES = [
  {
    name: 'Light',
    colorMode: 'light',
    icon: 'sun' as IconName,
    colors: [
      { hex: '#FFFFFF', sampleBg: 'linear-gradient(154deg, #EFEFEF 50%, rgba(255, 255, 255, 0.00) 330.86%)' },
    ],
  },
  {
    name: 'Dark',
    colorMode: 'dark',
    icon: 'moon' as IconName,
    colors: [
      { hex: 'linear-gradient(154deg, #0c1d31, #1f4a7d)', sampleBg: 'linear-gradient(161deg, #0c1d31, #1f4a7d)' },
    ],
  },
];

export type ColorTheme = typeof COLOR_THEMES[number];

export type ColorThemeColor = ColorTheme['colors'][number];
